import React, { useEffect, useState } from 'react';
import handleViewport from 'react-in-viewport';
import Video from './Video';

export default handleViewport(({ inViewport, innerRef, ...props }) => {
  const [muted, setMuted] = useState(true);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    if (!inViewport) {
      setMuted(true);
    } else {
      !playing && !props.autoPlay && setMuted(false);
    }
  }, [inViewport]);

  const handleVolumeChange = e => {
    setMuted(e.target.muted);
  };

  const handleOnPlaying = e => {
    setPlaying(true);
  };

  return (
    <div ref={innerRef}>
      <Video
        {...props}
        muted={muted}
        onPlaying={handleOnPlaying}
        onVolumeChange={handleVolumeChange}
      />
    </div>
  );
});
