import React from 'react';
import { findDOMNode } from 'react-dom';
import styled from 'styled-components';
import Fullscreen from './Fullscreen';
import Player from 'react-player';

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: ${props => `url(${props.overlayBackground})` || 'black'};
  background-size: cover;
  opacity: ${({ opacity }) => opacity};
  transition: opacity 1s ease-in-out;
  pointer-events: none;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
`;

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 56.25%;
`;

export default class Video extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      src: '',
      opacity: 0,
      loadingOverlay: 1,
      controls: false,
      duration: 0,
    };
    this.videoElement = React.createRef();
    this.loadHd = this.loadHd.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleTimeUpdate = this.handleTimeUpdate.bind(this);
    this.askFullscreen = this.askFullscreen.bind(this);
    this.handleOnReady = this.handleOnReady.bind(this);
  }

  componentDidMount() {
    this.setState({
      src:
        document.documentElement.clientWidth > 480
          ? this.props.hd
          : this.props.sd,
    });
  }

  loadHd() {
    if (typeof document !== 'undefined') {
      return document.documentElement.clientWidth > 480
        ? this.props.hd
        : this.props.sd;
    }
    return null;
  }

  handleMouseOver(e) {
    this.props.controls && this.setState({ controls: true });
  }

  handleMouseOut(e) {
    this.props.controls && this.setState({ controls: false });
  }

  handleTimeUpdate(e) {
    if (this.state.duration - e.playedSeconds < 0.3) {
      this.setState(state => (state.opacity = 1));
    } else if (e.playedSeconds > 0.3) {
      this.setState(state => (state.opacity = 0));
    }
  }

  askFullscreen(e) {
    function launchIntoFullscreen(element) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
    launchIntoFullscreen(findDOMNode(this.player).firstChild);
  }

  handleOnReady(e) {
    this.setState({ loadingOverlay: 0 });
  }

  ref = player => {
    this.player = player;
  };

  render() {
    return (
      <Wrapper className={this.props.className}>
        <VideoWrapper>
          <Player
            url={this.loadHd()}
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload noremoteplayback',
                  className: this.props.className,
                  poster: this.props.poster,
                  preload: this.props.autoPlay ? 'auto' : 'none',
                  onVolumeChange: this.props.onVolumeChange,
                  onPlaying: this.props.onPlaying,
                },
              },
              vimeo: {
                playerOptions: { responsive: true },
              },
              youtube: {
                playerVars: {
                  controls: this.props.controls ? 1 : 0,
                },
              },
            }}
            playing={this.props.autoPlay}
            width="100%"
            height="100%"
            style={{ position: 'absolute', top: 0, left: 0 }}
            poster={this.props.poster}
            ref={this.ref}
            // Show controls on hover if video is autoplay and controls is on
            controls={!this.props.autoPlay ? true : this.state.controls}
            volume={1}
            muted={
              this.props.isIndexVideo || this.props.controls
                ? this.props.muted
                : true
            }
            onMouseEnter={this.props.noMouseOver ? null : this.handleMouseOver}
            onMouseLeave={this.handleMouseOut}
            loop={this.props.loop}
            pip={false}
            playsinline
            onProgress={this.handleTimeUpdate}
            progressInterval={this.props.overlay ? 250 : 1000}
            onDuration={e => this.setState({ duration: e })}
            onReady={this.handleOnReady}
            onContextMenu={e => e.preventDefault()}
          />
          {this.props.isIndexVideo ? (
            <Overlay
              overlayBackground={this.props.overlayBackground}
              opacity={this.state.loadingOverlay}
            />
          ) : (
            this.props.overlay && (
              <Overlay
                overlayBackground={this.props.overlayBackground}
                opacity={this.state.opacity}
              />
            )
          )}
        </VideoWrapper>
        {this.props.fullscreen && <Fullscreen onClick={this.askFullscreen} />}
      </Wrapper>
    );
  }
}
